import React, { useState, useEffect, useCallback } from "react";
import Button from "../components/Button";
import Header from "../components/HeaderBlock";
import "../assets/style/end.scss";
import CryptoJS from "crypto-js";

import { ReactComponent as InstagramIcon } from "../assets/svgs/InstagramIcon.svg";
import { ReactComponent as Divider } from "../assets/svgs/Divider.svg";
import { ReactComponent as FacebookIcon } from "../assets/svgs/FacebookIcon.svg";
import { ReactComponent as SpanChatIcon } from "../assets/svgs/SpanChatIcon.svg";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptEmail = (email) => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
    const base64Ciphertext = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(ciphertext)
    );

    // Remplacer les caractères spéciaux par des caractères alphanumériques
    return base64Ciphertext
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, ""); // Remove padding characters
  } catch (error) {
    console.error("Encryption error: ", error);
    return null;
  }
};

const SocialShareButton = ({ platform, url, IconComponent, onClick }) => (
  <a
    href={url}
    className="share"
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
    aria-label={`Partager sur ${platform}`}
  >
    <div className="logo">
      <IconComponent />
    </div>
    <Divider />
    <span>{platform}</span>
  </a>
);

const EndScreen = ({ email }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const baseUrl = window.location.origin;
  const encryptedEmail = email ? encryptEmail(email) : null;
  const shareUrl = encryptedEmail
    ? `${baseUrl}?share=${encodeURIComponent(encryptedEmail)}`
    : baseUrl;

  useEffect(() => {
    console.log(email ? "Email reçu" : "Pas d'email");
  }, [email]);

  const handleCopyClick = useCallback(() => {
    // Ajoute l'événement de tracking Matomo pour le bouton "Copier le lien de partage"
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        "Partage", // Catégorie
        "Click", // Action
        "Copier le lien de partage", // Libellé
      ]);
    }

    navigator.clipboard.writeText(shareUrl).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  }, [shareUrl]);

  const handleHariboClick = () => {
    // Ajoute l'événement de tracking Matomo pour le bouton "Continuer sur haribo.fr"
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        "Page de fin", // Catégorie
        "Click", // Action
        "Continuer sur haribo.fr", // Libellé
      ]);
    }

    window.location.href = "https://www.haribo.com/fr-fr";
  };

  const handleSocialClick = (platform) => {
    // Ajoute l'événement de tracking Matomo pour les boutons de partage sur les réseaux sociaux
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        "Partage Réseaux Sociaux", // Catégorie
        "Click", // Action
        `Partager sur ${platform}`, // Libellé unique pour chaque plateforme
      ]);
    }
  };

  return (
    <div className="end-container">
      <Header />
      <div className="end-page">
        <div className="blue-bloc">
          <h2>Envie de prolonger l'expérience&nbsp;?</h2>
          <p className="end-text">
            Abonnez-vous pour ne rien louper.
          </p>

          <div className="social-share">
            <SocialShareButton
              platform="Instagram"
              url={`https://www.instagram.com/direct/new/?text=${encodeURIComponent(
                shareUrl
              )}`}
              IconComponent={InstagramIcon}
              onClick={() => handleSocialClick("Instagram")}
            />
            <SocialShareButton
              platform="Facebook"
              url={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                shareUrl
              )}`}
              IconComponent={FacebookIcon}
              onClick={() => handleSocialClick("Facebook")}
            />
            <SocialShareButton
              platform="Snapchat"
              url={`https://www.snapchat.com/submit?url=${encodeURIComponent(
                shareUrl
              )}`}
              IconComponent={SpanChatIcon}
              onClick={() => handleSocialClick("Snapchat")}
            />
            <div className="copy-button-container">
              <Button
                onClick={handleCopyClick}
                text="Copier le lien de partage"
              />
              {tooltipVisible && <span className="tooltip">Lien copié !</span>}
            </div>
          </div>

          <p className="instructions">
            Copiez le lien et collez-le dans Instagram, Snapchat, Facebook,
            WhatsApp ou message pour partager avec vos amis.
          </p>
        </div>

        <Button onClick={handleHariboClick} text="Continuer sur haribo.fr" />
      </div>
    </div>
  );
};

export default EndScreen;
