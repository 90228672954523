import React from "react";
import Button from "../components/Button";
import HeaderBlock from "../components/HeaderBlock";
import "../assets/style/regles.scss";
import ButtonBack from "../components/ButtonBack";

function RulePage({ nextStep, prevStep }) {
  // Wrapper pour ajouter le tracking Matomo lors du clic sur le bouton
  const handleNextStep = () => {
    // Ajoute l'événement de tracking Matomo
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        "Règles", // Catégorie spécifique pour la page des règles
        "Click", // Action
        "Bouton Entrer dans le monde parallèle", // Libellé spécifique
      ]);
    }

    // Appelle la fonction nextStep pour la logique existante
    if (typeof nextStep === "function") {
      nextStep();
    }
  };

  return (
    <>
      <ButtonBack onClick={prevStep} />
      <div className="regles-container">
        <HeaderBlock />
        <div className="regles-page">
          <div className="blue-bloc">
            <h2>comment ça marche ?</h2>
            <p className="blue">
              Bienvenue dans une aventure
              <br /> 100% sonore, loin des écrans :<br />
              <strong>laissez-vous guider.</strong>
            </p>
            <p>
              <strong>Adultes,</strong> vous êtes les maîtres du jeu : lancez
              les énigmes et préparez les accessoires nécessaires à chaque
              épreuve.
            </p>

            <div className="blue-light-bloc">
              <h2>
                Avant de commencer
                <br /> munissez-vous de:
              </h2>
              <div>
                <p>
                  1 bloc note avec 1 stylo <br />
                  -<br />
                  1 dé à 6 faces <br />
                  -<br />
                  1 miroir de poche <br />-<br />
                  1 cuillère en bois <br />
                  -<br />1 casserole avec une poignée
                </p>
              </div>
            </div>
          </div>

          {/* Bouton avec gestion du clic modifiée pour le tracking */}
          <Button
            text="ENTRER DANS LE MONDE PARALLÈLE"
            onClick={handleNextStep}
          />
        </div>
      </div>
    </>
  );
}

export default RulePage;
