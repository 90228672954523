import React, { useCallback, useEffect, useMemo, useState } from "react";
import ButtonAudio from "../components/ButtonAudio";
import "../assets/style/game.scss";
import HeaderBlock from "../components/HeaderBlock";
import Button from "../components/Button";
import useAudioPlayer from "../hooks/useAudioPlayer";
import ButtonBack from "../components/ButtonBack";

function GameScreen({ nextStep, prevStep }) {
  const [activeChapter, setActiveChapter] = useState(1);
  const [playingChapter, setPlayingChapter] = useState(null);
  const [completedChapters, setCompletedChapters] = useState([]);
  const [chapterProgress, setChapterProgress] = useState({});
  const [epilogueListened, setEpilogueListened] = useState(false);
  const [volume, setVolume] = useState(1); // Initial volume at 100%
  const [unlockedChapters, setUnlockedChapters] = useState(new Set());

  const chapters = useMemo(() => [1, 2, 3, 4, 5, 6], []);
  const [chapterTitles] = useState([
    "le mot méli-mélo",
    "Le miroir mystère",
    "La malle qui chante mal",
    "Le bonbon mystère",
    "la danSe d’halloween",
    "la fête d’halloween",
  ]);

  const { playAudio, pauseAudio, currentTime, duration, setAudioTime } =
    useAudioPlayer(volume);

  const unlockNextChapter = useCallback(() => {
    setActiveChapter((prevChapter) => {
      const nextChapter = prevChapter + 1;
      if (nextChapter <= chapters.length) {
        return nextChapter;
      }
      return prevChapter;
    });
  }, [chapters.length]);

  const unlockEpilogue = useCallback(() => {
    setEpilogueListened(true);
  }, []);

  useEffect(() => {
    if (playingChapter !== null && duration) {
      setChapterProgress((prev) => ({
        ...prev,
        [playingChapter]: (currentTime / duration) * 100,
      }));

      console.log(duration / 2);

      if (
        currentTime >= duration / 2 &&
        !unlockedChapters.has(playingChapter)
      ) {
        if (playingChapter === 6 && !epilogueListened) {
          unlockEpilogue();
        } else {
          unlockNextChapter();
        }
        setUnlockedChapters((prev) => new Set(prev).add(playingChapter));
      }
    }
  }, [
    currentTime,
    duration,
    playingChapter,
    unlockNextChapter,
    unlockEpilogue,
    unlockedChapters,
    epilogueListened,
  ]);

  const toggleAudioPlay = useCallback(
    (chapterNumber) => {
      // Ajoute l'événement de tracking Matomo pour chaque bouton audio
      if (window._paq) {
        window._paq.push([
          "trackEvent",
          `Page jeu - Chapitre ${chapterNumber}`, // Catégorie
          "Play", // Action
          `Chapitre ${chapterNumber}`, // Libellé unique par chapitre
        ]);
      }

      if (playingChapter === chapterNumber) {
        pauseAudio();
        setPlayingChapter(null);
      } else {
        pauseAudio();
        playAudio(chapterNumber);
        setPlayingChapter(chapterNumber);
      }
    },
    [playingChapter, playAudio, pauseAudio]
  );

  const handleProgressChange = (newValue, chapterNumber) => {
    if (duration) {
      const newTime = (newValue / 100) * duration;
      setAudioTime(newTime);
      setChapterProgress((prev) => ({
        ...prev,
        [chapterNumber]: duration ? (newTime / duration) * 100 : 0,
      }));
    }
  };

  const handleBackClick = () => {
    prevStep();
    pauseAudio();
  };

  const handleContinueClick = () => {
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        "Page jeu",
        "Click",
        "Découvrir la suite",
      ]);
    }

    nextStep();
    pauseAudio();
  };

  const getProgress = (chapter) => {
    if (playingChapter === chapter) {
      return duration ? (currentTime / duration) * 100 : 0;
    }
    if (chapterProgress[chapter] !== undefined) {
      return chapterProgress[chapter];
    }
    if (completedChapters.includes(chapter)) {
      return 100;
    }
    return 0;
  };

  return (
    <div className="game-container">
      <ButtonBack onClick={handleBackClick} />
      <HeaderBlock />
      <div className="game-page">
        <div className="blue-bloc">
          <div className="audios-buttons">
            {chapters.map((chapter, index) => {
              const isDisabled = index + 1 > activeChapter;
              return (
                <ButtonAudio
                  key={chapter}
                  text={chapterTitles[index]}
                  onClick={() => toggleAudioPlay(chapter)}
                  disabled={isDisabled}
                  isPlaying={playingChapter === chapter}
                  progress={getProgress(chapter)}
                  onSeek={(newValue) => handleProgressChange(newValue, chapter)}
                />
              );
            })}
          </div>
        </div>
        <Button
          disabled={!epilogueListened}
          onClick={handleContinueClick}
          text="découvrir la suite"
          isPlaying={false}
          progress={0}
        />
      </div>
    </div>
  );
}

export default GameScreen;
